.wrapper {
	width: 100%;
	background: url(./assets/start-page-bg.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}
.wrapper-two {
	width: 100%;
	height: 100vh;
	background: url(./assets/test.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
}
.wrapper-three {
	width: 100%;
	height: 100vh;
	background: url(./assets/registr-page-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
}
.wrapper-fourth {
	width: 100%;
	height: 100vh;
	background: url(./assets/intr-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
}
.wrapper-fifth {
	width: 100%;
	height: 100vh;
	background: url(./assets/contacts-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.container {
	width: 75%;
	margin: auto;
}
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.header-links {
	display: flex;
	align-items: center;
	gap: 100px;
	list-style-type: none;
}
.links {
	text-decoration: none;
	font-family: Nunito;
	font-size: 19px;
	font-weight: 500;
	line-height: 26px;
	letter-spacing: 0em;
	color: #000000;
}
.links:hover {
	border-bottom: 5px solid #3963FF;
	border-radius: 5px;
}
.main-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 34px 16px 0 0;
}
.main-block-left h2 {
	font-family: Nunito;
	font-size: 40px;
	font-weight: 700;
	line-height: 55px;
	letter-spacing: 0em;
	color: #000000;
	margin: 0;
}
.main-block-left p {
	font-family: Montserrat Alternates;
	font-size: 20px;
	font-weight: 500;
	line-height: 34px;
	letter-spacing: 0em;
	color: #000000;
	margin: 7px 0 19px 0;
}
.btns {
	font-family: Nunito;
	font-size: 24px;
	font-weight: 500;
	line-height: 33px;
	letter-spacing: 0em;
	color: #FFFFFF;
	background: #3963FF;
	border-radius: 20px;
	padding: 12.5px 58px;
	border: 0;
}
.btns:hover {
	cursor: pointer;
	background: #2745af;
}
.img-for-stupid-designer {
	margin-right: -122px;
}
#main-block-error {
	margin-top: 70px;
}
#main-block-error-p {
	margin: 10px 0 40px 0;
}
.select-test {
	font-family: Nunito !important;
	font-size: 19px !important;
	font-weight: 500 !important;
}
.select-test fieldset {
	display: none !important;
}
.modal-h2 {
	font-family: Nunito;
	font-size: 24px;
	font-weight: 500;
	margin: 0;
}
.modal-e {
	display: flex;
	justify-content: space-around;
	width: 100%;
}
.modal-ext {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 5px;
}
.modal-ext-cities {
	font-family: Nunito;
	font-size: 24px;
	font-weight: 500;
	padding: 10px;
	background: 0;
	border: 0;
	border-bottom: 2px solid #FFFFFF;
	color: #000000;
	text-decoration: none;
}
.modal-ext-cities:hover {
	cursor: pointer;
	border-bottom: 2px solid #3963FF;
	border-radius: 5px;
}
.modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}
.form {
	background: linear-gradient(180deg, #3963FF 0%, #233D9F 100%);
	padding: 17px;
	border-radius: 35px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
}
.form-for-reg {
	width: 50%;
}
.main-block-left2 {
	width: 45%;
}
.main-block-left2-reg {
	font-family: Nunito;
	font-size: 40px;
	font-weight: 700;
	color: #FFFFFF;
	text-align: center;
	margin: 0;
}
#outlined-basic {
	width: 100% !important;
}
.text-input {
	padding: 10px 15px;
	background-color: #9FB3FE66;
	outline: none;
	border: 0;
	border-radius: 28px;
	font-family: Nunito;
	color: #FFFFFF;
	font-size: 18px;
}
.text-input::-webkit-outer-spin-button,
.text-input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
.lbl-for-reg {
	font-family: Nunito;
	font-size: 20px;
	font-weight: 600;
	color: #FFFFFF;
}
.img-for-reg {
	margin-right: -100px;
}
.btn-with-img {
	width: 100px;
	height: 100px;
	padding: 0 !important;
	border-radius: 10px !important;
}
#for-mainblockleft2 {
	display: flex;
	width: 100%;
	align-items: end;
	gap: 15px;
}
.img-from-btn {
	width: 100%;
}
.main-block-two {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.main-block-two-head {
	font-family: Nunito;
	font-size: 40px;
	font-weight: 700;
}
.main-block-two-green {
	color: #FFFFFF;
	background-color: #75D04B;
	padding: 0 12px;
	border-radius: 25px;
}
.main-block-two-cards {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.main-block-two-cards-card {
	width: 19%;
	height: 335px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	text-align: center;
	background-color: #ECECEC;
	border-radius: 35px;
	padding: 15px 29px;
}
.main-block-two-cards-card-img {
	width: 196px;
}
.main-block-two-cards-card h3,p {
	margin: 0;
}
.main-block-two-alert-dop {
	margin-top: 15px;
	width: 100%;
	display: flex;
	gap: 20px;
}
.main-block-two-alert {
	display: flex;
	align-items: center;
	gap: 13px;
	width: 96.7%;
	background-color: #ECECEC;
	border-radius: 35px;
	padding: 22px;
}
.main-block-two-alert p {
	font-family: Nunito;
	font-size: 36px;
	font-weight: 400;
}
.btns2 {
	margin-top: 30px;
	border-radius: 35px;
}
.main-block-right2 {
	position: relative;
}
.btns3 {
	position: absolute;
	top: 200px;
	left: 120px;
	text-decoration: none;
}
.btns4 {
	top: 250px;
	left: 80px;
}
.img-for-login {
	margin-top: 50px;
}
.main-block-two-cards-card2 {
	text-decoration: none;
	color: #000000;
}
.modal2 {
	padding: 18px 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.modal2-left {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 30px;
}
.modal2-left-h2 {
	text-align: center;
	font-family: Nunito;
	font-size: 40px;
	font-weight: 700;
	margin: 0;
}
.modal2-left-p1 {
	font-family: Nunito;
	font-size: 24px;
	font-weight: 600;
	line-height: 25px;
}
.modal2-left-p2 {
	font-family: Nunito;
	font-size: 24px;
	font-style: italic;
	font-weight: 800;
}
.btns5 {
	font-size: 20px;
	font-weight: 500;
	padding: 5px 35px;
	border-radius: 10px;
	width: 61%;
}
.modal2-left-divp {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.info-result {
	display: flex;
	justify-content: space-between;
	font-family: Nunito;
	font-size: 19px;
	font-weight: 800;
	width: 100%;
	color: #3963FF;
}
.inner-for-type {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
}
.inner-for-type-p {
	background-color: #EBEFFF;
	border-radius: 35px;
	font-family: Nunito;
	font-size: 19px;
	font-weight: 500;
	padding: 21px;
	text-align: center;
	width: 83%;
}
.inner-for-type-p span {
	font-weight: 800;
}
.btns6 {
	margin-top: 10px;
	text-decoration: none;
}
.snackbar-red div {
	background-color: rgb(160, 0, 0) !important;
}
.main-block-three {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.disp-row {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}
.main-block-three h2 {
	font-family: Nunito;
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 0;
	margin-top: 50px;
}
.disp-row p, .disp-row2 p {
	font-family: Nunito;
	font-size: 20px;
	font-weight: 500;
}
.disp-row2 {
	display: flex;
	gap: 80px;
}
.disp-row2-divs {
	display: flex;
	flex-direction: column;
	gap: 12px;
}
.logout-btn {
	transition: .4s !important;
}
.logout-btn:hover {
	cursor: pointer;
	transform: scale(1.3);
}
.disabled {
	color: gray;
	pointer-events: none;
	cursor: default;
}
.inner-for-type-p2 {
	font-family: Nunito;
	font-size: 64px;
	font-weight: 500;
	line-height: 87.3px;
	text-align: center;
	border: 4px solid #3963FF;
	border-radius: 34px;
	padding: 2px 20px;
	background-color: #FFFFFF;
}
/*  */
/* ТЕСТ СКОРОПЕЧАТАНИЯ */
/*  */

.wrapper-for-type {
  width: 900px;
  padding: 35px 35px 0 35px;
  background: #ECECEC;
  border-radius: 10px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
}

.wrapper-for-type .input-field {
  opacity: 0;
  z-index: -999;
  position: absolute;
}

.content-box .typing-text {
  overflow: hidden;
  max-height: 256px;
}

.typing-text::-webkit-scrollbar {
  width: 0;
}

.typing-text p {
  text-align: justify;
	font-family: Nunito;
	font-size: 24px;
	font-weight: 700;
	line-height: 32.74px;
}

.typing-text p span {
  position: relative;
}

.typing-text p span.correct {
  color: #36B54B;
}

.typing-text p span.incorrect {
  color: #cb3439;
  background: #ffc0cb;
  border-radius: 4px;
}

.typing-text p span.active {
  color: deepskyblue;
}

.typing-text p span.active::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0;
  opacity: 0;
  border-radius: 5px;
  background: deepskyblue;
  animation: blink 1s ease-in-out infinite;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

.content-box .content {
  margin-top: 17px;
  display: flex;
  padding: 102px 0 0 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  line-break: auto;
}

.content button {
  outline: none;
  border: none;
  width: 105px;
  color: #fff;
  padding: 8px 0;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  background: deepskyblue;
  transition: transform 0.3s ease;
}

.content button:active {
  transform: scale(0.97);
}

.content .result-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.result-details li {
  display: flex;
  height: 20px;
  list-style: none;
  position: relative;
  align-items: center;
}

.result-details li:not(:first-child) {
  padding-left: 22px;
  border-left: 1px solid #bfbfbf;
}

.result-details li p {
  font-size: 19px;
}

.cpm {
	font-family: Nunito;
	font-size: 32px;
	font-weight: 700;
	line-height: 43.65px;
	text-align: center;
	color: #3963FF;
}

.errors span {
  color: #facc15;
}

.timeLeft span {
  color: #cb3439;
}

/*  */
/*  */
/*  */
.answer-button {
	display: flex;
	align-items: center;
  background-color: #EBEFFF;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
	font-family: Nunito;
	font-size: 29px;
	font-weight: 500;
	line-height: 39.56px;
	text-align: left;
	width: 100%;
	height: 55px;
}

.btns7 {
	margin-top: 32px;
	width: 220px;
	border-radius: 34px;
}

.question-comp-for-btns {
	display: flex;
	justify-content: center;
}

.answer-button:hover {
  background-color: #d1daff;
}

.answer-button.green {
  background-color: #B8F4C5;
}

.answer-button.red {
  background-color: #FFC8C8;
}

.answer-button:disabled,
.answer-button[disabled] {
  opacity: 1;
  cursor: not-allowed;
	color: black;
}

.custom-checkbox {
	display: flex;
	justify-content: space-between;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.custom-checkbox .checkmark {
  position: relative;
  display: inline-block;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 2px solid #000000;
	border-radius: 7px;
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  background-image: url(./assets/for-custom-check.png);
	background-repeat: no-repeat;
	background-size: cover;
  background-position: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
	width: 85%;
	height: 85%;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.question-comp {
	font-family: Nunito;
	font-size: 19px;
	font-weight: 500;
	margin-top: 10px;
}
.question-comp2 {
	font-family: Nunito;
	font-size: 26px;
	font-weight: 600;
	margin-bottom: 13px;
}
.question-comp-for-questions {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.question-comp-for-inputs {
	display: flex;
	align-items: center;
	gap: 10px;
}
.question-comp-for-final {
	margin-top: 200px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}
.logoProgHov:hover {
	cursor: pointer;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #1976d2;
}

.admin-panel {
	padding-bottom: 50px;
}

.admin-table th, .admin-table td {
  border: 1px solid #1976d2;
  padding: 8px;
  text-align: left;
}

.admin-panel h2 {
  margin-bottom: 20px;
}

.wrapper-finals {
	width: 100%;
	height: 100%;
	background: url(./assets/registr-page-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	min-height: 100vh;
}
.filter-container {
	margin: 20px 0;

}
.admin-panel-input {
	width: 100%;
	background-color: #FFFFFF;
	
}
.admin-panel-input input {
	font-family: Nunito !important;
	font-size: 20px !important;
	font-weight: 600 !important;
}
.cont-for-update h2 {
	margin: 0;
	font-size: 30px;
}
.cont-for-update {
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	gap: 70px;
	align-items: center;
}
.update-for-college {
	height: auto;
	width: 25%;
	transition: .5s;
}
.update-for-college:hover {
	cursor: pointer;
	transform: scale(1.2);
}
.main-block-two-cards-college {
	justify-content: space-around;
}


@media screen and (min-width: 320px) and (max-width: 520px) {
	.header-links {
		display: none;
	}
	.main-block {
		flex-direction: column-reverse;
		margin: 34px 0px 0 0;
	}
	.main-block-right {
		display: flex;
		justify-content: center;
	}
	.main-block-right img {
		width: 50%;
	}
	.main-block-left {
		text-align: center;
	}
	.main-block-left button {
		margin-bottom: 15px;
	}
	.wrapper {
		background-size: auto;
	}
	.modal-h2 {
		text-align: center;
	}
	.modal-e {
		flex-direction: column;
		height: 430px;
		overflow-y: auto;
	}
	.img-for-reg {
		margin-right: 0;
	}
	.btns3 {
		position: relative;
		top: 0;
		left: 0;
		height: 36px;
	}
	.main-block-right2 {
		align-items: center;
		gap: 5px;
	}
	.wrapper-three {
		height: 100%;
	}
	.img-for-login {
		margin-top: 0;
	}
	.btns4 {
		padding: 12.5px 28px;
	}
	.form-for-reg {
		width: 100%;
	}
	#for-mainblockleft2 {
		flex-direction: column;
		align-items: center;
		margin-top: 15px;
	}
	.img-from-btn {
		width: 70%;
	}
	.main-block-two-head {
		font-size: 36px;
		text-align: center;
	}
	.main-block-two-cards {
		flex-wrap: wrap;
		gap: 15px;
	}
	.main-block-two-cards-card {
		width: 85%;
	}
	.main-block-two-alert {
		width: 85%;
	}
	.main-block-two-alert p {
		font-size: 18px;
	}
	.main-block-two-alert-dop {
		flex-direction: column;
		align-items: center;
	}
	.btns2 {
		margin-bottom: 30px;
	}
	.modal2-left-p1 {
		display: none;
	}
	.modal2 {
		display: block;
		padding: 18px 0;
	}
	.modal2-right {
		display: flex;
		justify-content: center;
	}
	.modal2-right img {
		margin: 15px 0;
		width: 50%;
	}
	.btns5 {
		width: 100%;
	}
	.modal2-left-h2 {
		padding: 10px;
	}
	.modal2-left-p2 {
		text-align: center;
	}
	.question-comp-for-questions {
		width: 85%;
	}
	.btns7 {
		margin-bottom: 25px;
	}
	.answer-button {
		height: auto;
		font-size: 22px;
	}
	.inner-for-type img {
		width: 100%;
	}
	.content-box .typing-text {
		max-height: 550px;
	}
	.wrapper-for-type {
		width: 100%;
		margin-bottom: 10px;
	}
	.cont-for-update {
		flex-wrap: wrap;
		margin: 20px 0;
	}
}
@media screen and (min-width: 520px) and (max-width: 768px) {
	.header-links {
		display: none;
	}
	.main-block {
		flex-direction: column-reverse;
		margin: 34px 0px 0 0;
	}
	.main-block-right {
		display: flex;
		justify-content: center;
	}
	.main-block-right img {
		width: 50%;
	}
	.main-block-left {
		text-align: center;
	}
	.main-block-left button {
		margin-bottom: 15px;
	}
	.wrapper {
		background-size: auto;
	}
	.modal-h2 {
		text-align: center;
	}
	.modal-e {
		flex-direction: column;
		height: 430px;
		overflow-y: auto;
	}
	.img-for-reg {
		margin-right: 0;
	}
	.btns3 {
		position: relative;
		top: 0;
		left: 0;
		height: 36px;
	}
	.main-block-right2 {
		align-items: center;
		gap: 5px;
	}
	.wrapper-three {
		height: 100%;
	}
	.img-for-login {
		margin-top: 0;
	}
	.btns4 {
		padding: 12.5px 28px;
	}
	.form-for-reg {
		width: 100%;
	}
	#for-mainblockleft2 {
		flex-direction: column;
		align-items: center;
		margin-top: 15px;
	}
	.img-from-btn {
		width: 70%;
	}
	.main-block-two-head {
		font-size: 36px;
		text-align: center;
	}
	.main-block-two-cards {
		flex-wrap: wrap;
		gap: 15px;
	}
	.main-block-two-cards-card {
		width: 38%;
	}
	.main-block-two-alert {
		width: 85%;
	}
	.main-block-two-alert p {
		font-size: 18px;
	}
	.main-block-two-alert-dop {
		flex-direction: column;
		align-items: center;
	}
	.btns2 {
		margin-bottom: 30px;
	}
	.modal2-left-p1 {
		display: none;
	}
	.modal2 {
		display: block;
		padding: 18px 0;
	}
	.modal2-right {
		display: flex;
		justify-content: center;
	}
	.modal2-right img {
		margin: 15px 0;
		width: 50%;
	}
	.btns5 {
		width: 100%;
	}
	.modal2-left-h2 {
		padding: 10px;
	}
	.modal2-left-p2 {
		text-align: center;
	}
	.question-comp-for-questions {
		width: 94%;
	}
	.btns7 {
		margin-bottom: 25px;
	}
	.answer-button {
		height: auto;
		font-size: 22px;
	}
	.inner-for-type img {
		width: 100%;
	}
	.content-box .typing-text {
		max-height: 550px;
	}
	.wrapper-for-type {
		width: 100%;
		margin-bottom: 10px;
	}
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
	.main-block-two-cards {
		flex-wrap: wrap;
		gap: 15px;
	}
	.main-block-two-cards-card {
		width: 30%;
	}
}